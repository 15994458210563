"use strict";

var scrollToAnchor = function scrollToAnchor() {
  var scrollToAnchorDuration = 800;
  $('a[href*="#"][data-js="scroll"]').on('click', function (e) {
    e.preventDefault();
    var target = this.hash,
        $target = $(target);
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top
    }, scrollToAnchorDuration, 'easeOutExpo', function () {
      window.location.hash = target;
    });
  });
};