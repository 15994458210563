"use strict";

jQuery.fn.exists = function () {
  return this.length > 0;
  /*
  use:
  if ($(selector).exists()) {
      // Do something
  }
  */
}; // --------------------------------------------------------------------------------
//	init slick-carousel
// --------------------------------------------------------------------------------


var slickCarousel = function slickCarousel() {
  _l('init slickCarousel()...');

  "use strict";

  var Selector = {
    SLICK: '[data-init="slick"]'
  };
  $(Selector.SLICK).slick({
    dots: true,
    lazyLoad: 'ondemand',
    prevArrow: '<button type="button" data-role="none" class="btn btn-lg btn-secondary text-primary slick-prev" aria-label="zurück" role="button" ><img src="/svg/arrow.svg" alt=""><span class="sr-only">zurück</span></button>',
    nextArrow: '<button type="button" data-role="none" class="btn btn-lg btn-secondary text-primary slick-next" aria-label="weiter" role="button" ><img src="/svg/arrow.svg" alt=""><span class="sr-only">weiter</span></button>'
  });
}; // --------------------------------------------------------------------------------
//	init simpleLightbox
// --------------------------------------------------------------------------------


var simpleLightbox = function simpleLightbox() {
  _l('init simpleLightbox()...');

  alert('simpleLightbox!');
  "use strict";

  var Selector = {
    SIMPLELIGHTBOX: '[data-init="simpleLightbox"]'
  };
  $(document).on('click', Selector.SIMPLELIGHTBOX, function (event) {
    event.preventDefault();
    var card = $(this).parents('.card');
    var group = card.find('a');
    var arrow = "<span class=\"btn btn-lg btn-secondary\">\n" + "<img class=\"d-block\" src=\"/svg/arrow.svg\" alt=\"\">\n" + "</span>";
    var lightbox = group.simpleLightbox({
      fileExt: false,
      navText: [arrow, arrow],
      history: false,
      widthRatio: 0.9,
      heightRatio: 0.8 //docClose: false
      //additionalHtml: html
      //captionSelector: caption,
      //captionType: 'data',
      //captionSelector: 'self', // 	string 	set the element where the caption is. Set it to "self" for the A-Tag itself
      //captionType: 'attr', // 	string 	how to get the caption. You can choose between attr, data or text
      //captionsData: 'href', // 	string 	get the caption from given attribute
      //captionPosition: 'outside'

    });
    lightbox.on('shown.simplelightbox', function () {
      _l('shown.simplelightbox'); //$('.sl-wrapper .custom-caption').remove();
      //$('.sl-wrapper').append( $('<div class="custom-caption"/>').html( card.find('.card-body').html() ) );

    });
    lightbox.on('changed.simplelightbox', function () {
      _l('changed.simplelightbox');
    });
    lightbox.on('close.simplelightbox', function () {
      _l('close.simplelightbox');
    });
    lightbox.on('closed.simplelightbox', function () {
      _l('closed.simplelightbox');

      lightbox.destroy();
      group.off();
    });
    lightbox.open();
  });
};