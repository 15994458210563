"use strict";

$(function () {
  $('.slick-modal').on('show.bs.modal', function (event) {
    //$('.my-slick img').hide();
    //_l(event.relatedTarget)
    _l(event);

    var html = $(event.relatedTarget).parents('.card').find('.card-body').html(); //$(event.target).find('.slick-footer').html(html);

    $(this).find('.slick-footer').html(html); // get slides

    var slides = $(event.relatedTarget).parents('.card').find('.slick-slides').html();
    $(this).find('.my-slick').html(slides);
  });
  $('.slick-modal').on('shown.bs.modal', function () {
    $(this).niceScroll({
      cursorcolor: 'black',
      cursoropacitymin: 0.1,
      cursoropacitymax: 0.6,
      cursorwidth: '6px',
      cursorborder: 'none',
      cursorborderradius: '3px'
    });
  });
  $('.slick-modal').on('shown.bs.modal', function () {
    //$('.my-slick img').show();
    $('.my-slick').slick({
      dots: true,
      appendDots: $('.slick-dots-cnt'),
      //slide: 'img',
      //rows: 0,
      lazyLoad: 'progressive',
      //prevArrow: '.c-slick-prev',
      //nextArrow: '.c-slick-next',
      appendArrows: '.modal',
      prevArrow: '<button type="button" class="btn btn-lg btn-secondary c-slick-prev"><img class="d-block" src="/svg/arrow.svg" alt="icon, Pfeil"></button>',
      nextArrow: '<button type="button" class="btn btn-lg btn-secondary c-slick-next"><img class="d-block" src="/svg/arrow.svg" alt="icon, Pfeil"></button>',
      // customPaging: function(slider, i) {
      // 	return $('<button class="btn btn-secondary" type="button" />').text(i + 1);
      // }
      responsive: [{
        breakpoint: 768,
        settings: {
          //dots: false,
          //arrows: false,
          //appendDots: $('.modal-content > .embed-responsive'),
          appendArrows: '.my-slick'
        }
      }, {
        breakpoint: 576,
        settings: {
          //arrows: false,
          appendArrows: '.my-slick'
        }
      }]
    });
  });
  $('.slick-modal').on('hidden.bs.modal', function () {
    $('.my-slick').slick('unslick');
  });
});