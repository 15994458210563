"use strict";

/**
 * User: Michael.Schaubruch
 * Date: 19.06.2020
 * Time: 10:23
 */
//  Google Maps, VELUX
//  Hinweis ausblenden, wenn Marketing Cookie vorhanden
if (Cookiebot.consent.marketing == 1) {
  $('.js-cookie-marketing-not-set-info').hide();
}

if (Cookiebot) {
  $(document).on('click', '#CybotCookiebotDialogBodyButtonAccept', function () {
    var detailsOpen = $('#CybotCookiebotDialogBodyButtonDetails').hasClass('CybotCookiebotDialogBodyLinkExpanded');

    if (!detailsOpen) {
      Cookiebot.submitCustomConsent(true, true, true);
      Cookiebot.settingsOpened = true;
    }
  });
}