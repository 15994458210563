"use strict";

$(function () {
  "use strict";

  var target = document.querySelector('.kw-module-0100__overlay');
  var speed = 0.4;

  if (target) {
    var setScrollParallax = function setScrollParallax() {
      target.style.setProperty("--scrollparallax", (document.body.scrollTop || document.documentElement.scrollTop) * speed);
      window.requestAnimationFrame(setScrollParallax);
    };

    target.style.transform = 'translateY( calc( var(--scrollparallax) * 1px ) )';
    window.requestAnimationFrame(setScrollParallax);
  }
});