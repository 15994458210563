"use strict";

/**
 * User: Michael.Schaubruch
 * Date: 29.11.2018
 * Time: 16:46
 */
function _l(msg) {//console.log(msg);
}

$(function () {
  "use strict"; // TEST
  // const A = "A";
  // --------------------------------------------------
  //	init scrollToAnchor
  // --------------------------------------------------

  scrollToAnchor(); // --------------------------------------------------
  //	hamburger collapse, navbar
  //
  // --------------------------------------------------

  $('.navbar-collapse').on('show.bs.collapse', function () {
    $('.hamburger').addClass('is-active');
    $('.navbar').addClass('is-active');
    $('html').addClass('navbar-open');
  });
  $('.navbar-collapse').on('hide.bs.collapse', function () {
    $('.hamburger').removeClass('is-active');
    $('.navbar').removeClass('is-active');
    $('html').removeClass('navbar-open');
  }); // --------------------------------------------------
  //	init slick
  // --------------------------------------------------

  if ($('[data-init=slick]').exists()) {
    slickCarousel();
  } // --------------------------------------------------
  //	keypress
  // --------------------------------------------------
  //
  // $('body').attr('contenteditable', false);
  // $(document).keypress(function (event) {
  // 	_l("Handler for .keypress() called.");
  // 	_l(event.key);
  // 	if (event.key === 'e')
  // 		$('body').attr('contenteditable', $('body').attr('contenteditable') == 'false' ? 'true' : 'false');
  //     if (event.key === 'd')
  //         $('body').toggleClass('develop');
  // });
  // --------------------------------------------------
  //	jQuery.niceScroll
  // --------------------------------------------------
  //$("body").niceScroll();
  // --------------------------------------------------
  //	waypoints
  // --------------------------------------------------
  // var sticky = new Waypoint.Sticky({
  // 	element: $('.navbar'),
  // 	//stuckClass: 'myStuckClass',
  // 	//wrapper: '<div class="sticky-top" />'
  // });
  // --------------------------------------------------
  //	headroom.js
  // --------------------------------------------------


  var header = document.querySelector('[data-js="headroom"]');

  if (window.location.hash) {
    header.classList.add("headroom--unpinned");
  }

  if ($(document).scrollTop()) {
    header.classList.add("headroom--pinned");
  }

  var headroom = new Headroom(header, {
    tolerance: {
      down: 5,
      up: 10
    },
    offset: 200
  });
  headroom.init(); // --------------------------------------------------
  //	Bootstrap validation
  // --------------------------------------------------
  //window.addEventListener('load', function() {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to

  var forms = document.getElementsByClassName('needs-validation'); // Loop over them and prevent submission

  var validation = Array.prototype.filter.call(forms, function (form) {
    // KW
    form.setAttribute('novalidate', true); //

    form.addEventListener('submit', function (event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      form.classList.add('was-validated');
    }, false);
  }); //}, false);
  // --------------------------------------------------
  //	unveil images
  // --------------------------------------------------

  var unveilDuration = 300;
  $('img.js-unveil').unveil(unveilDuration, function () {
    $(this).on('load', function () {
      this.style.opacity = 1;
      this.classList.add('unveil-loaded');
    });
  }); // --------------------------------------------------
  //	objectFitImages polyfill
  // --------------------------------------------------
  // 1. unveil images

  $('.no-object-fit img.ofit').trigger('unveil'); // 2. polyfill

  objectFitImages('img.ofit'); // --------------------------------------------------
  //	custom b4 accordion/collapse
  // --------------------------------------------------
  // $('#accordionExample').on('show.bs.collapse', function () {
  //
  // 	if (device.mobile())
  // 		return;
  //
  // 	$('.show').collapse('hide');
  // })

  /*
  // --------------------------------------------------
  //	horizontal scroll fixed element
  // --------------------------------------------------
  var $target = $('.headroom');
  var leftInit = 0; //$target.offset().left;
  //var top = $target.offset().top - parseFloat($target.css('margin-top').replace(/auto/, 0));
    $(window).scroll(function(event) {
        var x = 0 - $(this).scrollLeft();
      //var y = $(this).scrollTop();
        // whether that's below the form
      // if (y >= top) {
      //     // if so, ad the fixed class
      //     $target.addClass('fixed');
      // } else {
      //     // otherwise remove it
      //     $target.removeClass('fixed');
      // }
        $target.css({
          left: x + leftInit
      });
    });
  */
});